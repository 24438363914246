import { videos }  from "../../../Media/Media";
import React from 'react'


//css
import './BlocoVideo.css'

function BlocoVideo(){
    // s


    return(

      <div className="main1">
        <video src='https://pub-fe8ed08053384954a6c222c87d633527.r2.dev/video2.mp4' autoPlay loop muted />

      </div>
    )
}

export default BlocoVideo