import Headers from "../components/header/header/Headers";
import StoryBoards2 from "../components/header/body/StoryBoards2";
import Footer from "../components/header/footer/Footer";

function PaginaStoryBoards(){

    return(
        <>
        <Headers/>
        <StoryBoards2/>
        <Footer/>
      
        </>
    )
}

export default PaginaStoryBoards;