import Headers from "../components/header/header/Headers";
import Footer from "../components/header/footer/Footer";

import Main2 from "../components/header/body/Main2";

function PaginaIA(){

    return(
        <>
        
            <Headers/>
            <Main2/>
            <Footer/>
        
        
        </>

    )
}

export default PaginaIA;