import "./Form.css"; // Mantém o CSS
import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";

function Form() {
  const [state, handleSubmit] = useForm("xldgwpaw"); // Seu ID do Formspree
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(e);

    if (state.succeeded) {
      setShowSuccess(true);
      setName("");
      setEmail("");
      setMessage("");
      setTimeout(() => setShowSuccess(false), 3000); // Esconde após 3s
    }
  };

  return (
    <div className="cont">
      <h1 className="title">Contato</h1>

      {showSuccess && (
        <div className="success-popup">
          <p>Email enviado com sucesso! 🎉</p>
        </div>
      )}

      <form className="form" onSubmit={handleFormSubmit}>
        <input
          className="input"
          type="text"
          placeholder="Digite seu nome"
          name="name"
          onChange={(e) => setName(e.target.value)}
          value={name}
          required
        />
        <input
          className="input"
          type="email"
          placeholder="Digite seu email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        <textarea
          className="textarea"
          placeholder="Digite sua mensagem..."
          name="message"
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          required
        />
        <ValidationError prefix="Message" field="message" errors={state.errors} />
        <button className="button" type="submit" disabled={state.submitting}>
          Enviar!
        </button>
      </form>
    </div>
  );
}

export default Form;
