import Headers from "../components/header/header/Headers";

import Ilustra2 from "../components/header/body/Ilustra2";
import Footer from "../components/header/footer/Footer";


function Conteudo(){

    return (
        <>
        <Headers/>
        <Ilustra2/>
        <Footer/>
        </>
    )
        

    
}

export default Conteudo