import Headers from "../components/header/header/Headers";
import Animatics22 from "../components/header/body/Animatics2.2";
import Footer from "../components/header/footer/Footer";


function Animatics(){
    return (
        <>
        <Headers/>
        <Animatics22/>
        <Footer/>
        </>
    )
}

export default Animatics;